import { DynamicScriptType } from '@plainly/types';
import { LayerManagementScript, Template } from '@src/models';
import { SelectedDynamicScripts } from '@src/types';
import { includesSafe } from '@src/utils';

/**
 * Given an template, go through the array of layers, returns an array of dynamic scripts.
 *
 * Dynamic scripts are LayerManagementScript and ImageSequenceLoaderScript objects.
 * Each dynamic script is an object with the following properties:
 * - parameterName: the name of the parameter.
 * - scriptType: the type of the script (either DynamicScriptType.LAYER_MANAGEMENT or DynamicScriptType.IMAGE_SEQUENCE_LOADER).
 *
 * @param {Template} template template with layers
 * @returns {Array<{parameterName: string, scriptType: DynamicScriptType}>} an array of dynamic scripts.
 */
export const getDynamicScripts = (
  template?: Template
): Array<{ parameterName: string; scriptType: DynamicScriptType }> | undefined | [] => {
  if (!template || !template.layers) return undefined;

  const dynamicScripts = template?.layers.flatMap(layer => {
    if (layer.scripting) {
      return layer.scripting.scripts.flatMap(script => {
        if (includesSafe([DynamicScriptType.LAYER_MANAGEMENT], script.scriptType)) {
          return {
            parameterName: (script as LayerManagementScript).parameterName,
            scriptType: script.scriptType as DynamicScriptType
          };
        }

        return [];
      });
    }
    return [];
  });

  return dynamicScripts;
};

/**
 * Given a key and a value from a layer management parameter, convert the value to the expected type.
 *
 * If the value is undefined or an empty string, return undefined.
 * If the key is "duration" or "startTime", return the value as a number.
 * If the key is "fontSize", return the value as an integer.
 * If the key is "enabled" or "audioEnabled", return the value as a boolean.
 * Otherwise, return the value as a string.
 *
 * @param {string} key - the key of the parameter
 * @param {string | undefined} value - the value of the parameter
 * @returns {string | number | boolean | undefined} the value converted to the expected type
 */
export const handleLayerManagementParams = (
  key: string,
  value: string | undefined
): string | number | boolean | undefined => {
  if (value === undefined || value === '') return undefined;
  if (includesSafe(['duration', 'startTime'], key)) return parseFloat(value);
  if (includesSafe(['fontSize'], key)) return parseInt(value);
  if (includesSafe(['enabled', 'audioEnabled'], key)) return handleBoolean(value);

  return value;
};

/**
 * Converts a given string value to a boolean.
 *
 * This function is case-insensitive and will return true for any of the following values:
 * - "true"
 * - "1"
 * - "yes"
 *
 * This function will return false for any of the following values:
 * - "false"
 * - "0"
 * - "no"
 *
 * If the given value does not match any of the above, this function will return undefined.
 *
 * @param {string} value - the value to be converted to a boolean
 * @returns {boolean | undefined} the converted value
 */
export const handleBoolean = (value: string) => {
  if (/^(true|1|yes)$/i.test(value)) return true;
  if (/^(false|0|no)$/i.test(value)) return false;

  return undefined;
};

export const constructSelectedDynamicScripts = (
  parameterValues: Record<string, string | Record<string, string | number | boolean>> | undefined,
  dynamicScripts: Array<{ parameterName: string; scriptType: DynamicScriptType }>
) => {
  const newSelectedDynamicScripts: SelectedDynamicScripts = {};

  dynamicScripts.forEach(script => {
    if (script?.scriptType === DynamicScriptType.LAYER_MANAGEMENT) {
      const duration = (parameterValues?.[script.parameterName] as Record<string, string>)?.duration;
      const startTime = (parameterValues?.[script.parameterName] as Record<string, string>)?.startTime;
      const fontSize = (parameterValues?.[script.parameterName] as Record<string, string>)?.fontSize;
      const font = (parameterValues?.[script.parameterName] as Record<string, string>)?.font;
      const enabled = (parameterValues?.[script.parameterName] as Record<string, string>)?.enabled;
      const audioEnabled = (parameterValues?.[script.parameterName] as Record<string, string>)?.audioEnabled;

      newSelectedDynamicScripts[script.parameterName] = {
        duration: { value: duration || undefined, isColumnSelect: duration ? false : true },
        startTime: { value: startTime || undefined, isColumnSelect: startTime ? false : true },
        fontSize: { value: fontSize || undefined, isColumnSelect: fontSize ? false : true },
        font: { value: font || undefined, isColumnSelect: font ? false : true },
        enabled: { value: enabled ?? undefined, isColumnSelect: enabled === undefined },
        audioEnabled: { value: audioEnabled ?? undefined, isColumnSelect: audioEnabled === undefined }
      };
    }
  });

  return newSelectedDynamicScripts;
};
