import { useCallback, useEffect, useReducer } from 'react';

import { RenderOptionsDto } from '@src/models';
import { BatchRenderPassthrough, SelectedDynamicScripts } from '@src/types';

const storageKey = 'plainly.batch-render';

type State = {
  tableData: Record<string, string>[];
  selectedColumns: { [param: string]: { value: string; isColumnSelect: boolean } };
  selectedDynamicScripts: SelectedDynamicScripts;
  webhookPassthrough: BatchRenderPassthrough;
  integrationsPassthrough: BatchRenderPassthrough;
  advancedOptions: RenderOptionsDto;
};

type Action =
  | { type: 'update-table-data'; payload: Record<string, string>[] }
  | { type: 'clear-table-data' }
  | { type: 'update-selected-columns'; payload: { [param: string]: { value: string; isColumnSelect: boolean } } }
  | { type: 'clear-selected-columns' }
  | { type: 'update-selected-dynamic-scripts'; payload: SelectedDynamicScripts }
  | { type: 'update-webhook-passthrough'; payload: BatchRenderPassthrough }
  | { type: 'clear-webhook-passthrough' }
  | { type: 'update-integrations-passthrough'; payload: BatchRenderPassthrough }
  | { type: 'clear-integrations-passthrough' }
  | { type: 'update-advanced-options'; payload: RenderOptionsDto }
  | { type: 'clear-advanced-options' }
  | { type: 'clear-state' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'update-table-data':
      return {
        tableData: action.payload,
        selectedColumns: {},
        webhookPassthrough: { passthrough: {}, sendAsJson: false },
        integrationsPassthrough: { passthrough: {}, sendAsJson: false },
        advancedOptions: {},
        selectedDynamicScripts: {}
      };

    case 'update-selected-columns':
      return { ...state, selectedColumns: action.payload };

    case 'clear-selected-columns':
      return { ...state, selectedColumns: {} };

    case 'update-selected-dynamic-scripts':
      return { ...state, selectedDynamicScripts: action.payload };

    case 'update-webhook-passthrough':
      return { ...state, webhookPassthrough: action.payload };

    case 'clear-webhook-passthrough':
      return { ...state, webhookPassthrough: { passthrough: {}, sendAsJson: false } };

    case 'update-integrations-passthrough':
      return { ...state, integrationsPassthrough: action.payload };

    case 'clear-integrations-passthrough':
      return { ...state, integrationsPassthrough: { passthrough: {}, sendAsJson: false } };

    case 'update-advanced-options':
      return { ...state, advancedOptions: action.payload };

    case 'clear-advanced-options':
      return { ...state, advancedOptions: {} };

    case 'clear-state':
      return emptyStateObject;

    default:
      return state;
  }
}

const emptyStateObject = {
  tableData: [],
  selectedColumns: {},
  selectedDynamicScripts: {},
  webhookPassthrough: { passthrough: {}, sendAsJson: false },
  integrationsPassthrough: { passthrough: {}, sendAsJson: false },
  advancedOptions: {}
};

const initializer = (initialValue = emptyStateObject) => {
  const storedState = sessionStorage.getItem(storageKey);
  if (storedState) {
    return JSON.parse(storedState);
  } else {
    return initialValue;
  }
};

export const useRenderBatchReducer = () => {
  const [state, dispatch] = useReducer(reducer, initializer());

  useEffect(() => {
    if (state) {
      sessionStorage.setItem(storageKey, JSON.stringify(state));
    } else {
      sessionStorage.removeItem(storageKey);
    }
  });

  const updateTableData = useCallback(
    (payload?: Record<string, string>[]) => {
      if (payload) {
        dispatch({ type: 'update-table-data', payload });
      } else {
        dispatch({ type: 'clear-state' });
      }
    },
    [dispatch]
  );

  const updateSelectedColumns = useCallback(
    (payload?: { [param: string]: { value: string; isColumnSelect: boolean } }) => {
      if (payload) {
        dispatch({ type: 'update-selected-columns', payload });
      } else {
        dispatch({ type: 'clear-selected-columns' });
      }
    },
    [dispatch]
  );

  const updateSelectedDynamicScripts = useCallback(
    (payload: SelectedDynamicScripts) => {
      dispatch({ type: 'update-selected-dynamic-scripts', payload });
    },
    [dispatch]
  );

  const updateWebhookPassthrough = useCallback(
    (payload?: BatchRenderPassthrough) => {
      if (payload) {
        dispatch({ type: 'update-webhook-passthrough', payload });
      } else {
        dispatch({ type: 'clear-webhook-passthrough' });
      }
    },
    [dispatch]
  );

  const updateIntegrationsPassthrough = useCallback(
    (payload?: BatchRenderPassthrough) => {
      if (payload) {
        dispatch({ type: 'update-integrations-passthrough', payload });
      } else {
        dispatch({ type: 'clear-integrations-passthrough' });
      }
    },
    [dispatch]
  );

  const updateAdvancedOptions = useCallback(
    (payload?: RenderOptionsDto) => {
      if (payload) {
        dispatch({ type: 'update-advanced-options', payload });
      } else {
        dispatch({ type: 'clear-advanced-options' });
      }
    },
    [dispatch]
  );

  const clearRenderBatchState = useCallback(() => {
    dispatch({ type: 'clear-state' });
  }, [dispatch]);

  return [
    state,
    updateTableData,
    updateSelectedColumns,
    updateWebhookPassthrough,
    updateIntegrationsPassthrough,
    updateAdvancedOptions,
    updateSelectedDynamicScripts,
    clearRenderBatchState
  ] as const;
};
