import React, { useCallback, useEffect, useState } from 'react';

import { CheckIcon, SignalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

type RenderInputProps<T> = {
  disabled: boolean;
  autoFocus: boolean;
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
};

type UpdatableInputProps<T> = {
  value: T;
  loading: boolean;
  onUpdate: (value: T) => Promise<void>;
  renderInput: (props: RenderInputProps<T>) => React.ReactNode;
};

export const UpdatableInput = <T,>({ value, loading, onUpdate, renderInput }: UpdatableInputProps<T>) => {
  const [localValue, setLocalValue] = useState<T>(value);
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const cancel = useCallback(() => {
    setEditing(false);
    setLocalValue(value);
  }, [value]);

  const handleUpdate = useCallback(async () => {
    if (localValue !== value) {
      await onUpdate(localValue);
      cancel();
    } else {
      cancel();
    }
  }, [cancel, localValue, onUpdate, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.key === 'Escape') cancel();
    },
    [cancel]
  );

  return (
    <>
      {!editing && (
        <div
          className="group flex w-full max-w-fit cursor-pointer items-center gap-1 truncate"
          onClick={() => setEditing(true)}
        >
          <span className="truncate duration-150 group-hover:text-gray-600">{String(value)}</span>
          <PencilSquareIcon className="h-4 w-4 text-gray-900 duration-150 group-hover:text-gray-600" />
        </div>
      )}
      {editing && (
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleUpdate();
          }}
          onKeyDown={handleKeyDown}
          className="flex w-full items-center gap-1"
        >
          <div className="relative flex w-full items-center gap-1">
            {renderInput({
              disabled: loading,
              autoFocus: true,
              value: localValue,
              setValue: setLocalValue
            })}
            {loading && <SignalIcon className="absolute right-1 h-4 w-4 shrink-0 animate-spin text-gray-500" />}
          </div>
          <button
            disabled={loading}
            className="group flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-md bg-gray-100 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            type="submit"
          >
            <CheckIcon className="h-4 w-4 shrink-0 text-green-700 group-hover:text-green-600" />
          </button>
          <button
            disabled={loading}
            className="group flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-md bg-gray-100 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            type="button"
            onClick={cancel}
          >
            <XMarkIcon className="h-4 w-4 shrink-0 text-red-700 group-hover:text-red-600" />
          </button>
        </form>
      )}
    </>
  );
};
