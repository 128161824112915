import { useCallback, useMemo } from 'react';

import { DynamicScriptType } from '@plainly/types';
import { LayerManagementParameterDto, RenderParameters } from '@src/models';
import { convertDotsToNested } from '@src/utils';

import { DynamicScriptLayerManagement } from './DynamicScriptLayerManagement';

export const DynamicScriptSingleRenderWrapper = ({
  renderParameters,
  parameterName,
  scriptType,
  onChange
}: {
  renderParameters: RenderParameters | undefined;
  parameterName: string;
  scriptType: DynamicScriptType;
  onChange: (
    parameterName: string,
    value: {
      [key: string]: string | number | boolean | readonly string[] | undefined;
    }
  ) => void;
}) => {
  const nestedParameters = useMemo(() => renderParameters && convertDotsToNested(renderParameters), [renderParameters]);
  const scriptParameter = useMemo(() => nestedParameters?.[parameterName], [nestedParameters, parameterName]);

  const handleSingleChange = useCallback(
    (value: { [key: string]: string | number | boolean | readonly string[] | undefined }) =>
      onChange(parameterName, value),
    [onChange, parameterName]
  );

  return (
    <>
      {scriptType === DynamicScriptType.LAYER_MANAGEMENT && (
        <DynamicScriptLayerManagement
          batchRender={false}
          parameter={scriptParameter as LayerManagementParameterDto}
          onChange={handleSingleChange}
        />
      )}
    </>
  );
};
