import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useBreakpoint, useGetUserInvitations, useGetUserProfile, useSwitchOrganization } from '@src/hooks';
import { User } from '@src/models';
import * as routes from '@src/routes';
import { State, useGlobalState } from '@src/state/store';
import { organizationInitials, usernameInitials } from '@src/utils';

export const UserProfileAvatar = ({
  toggleSideBar,
  sidebarDesktopFullyCollapsed
}: {
  toggleSideBar?: () => void;
  sidebarDesktopFullyCollapsed?: boolean;
}) => {
  const [user] = useGlobalState(State.USER);
  const [activeOrganization] = useGlobalState(State.ORGANIZATION);
  const [switchOrgView, setSwitchOrgView] = useState(false);
  const disableSwitchOrgView = useCallback(() => setSwitchOrgView(false), []);

  return (
    <Menu as="div" className="relative inline-block pt-2 text-left md:pt-0">
      <MenuButton onClick={disableSwitchOrgView}>
        {!sidebarDesktopFullyCollapsed ? (
          <div className="group flex cursor-pointer items-center pl-2 pr-4">
            <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-sm font-semibold text-gray-100 duration-150 group-hover:bg-indigo-600 group-hover:text-white">
              <p>{organizationInitials(activeOrganization?.name)}</p>
            </div>
            <div className="flex h-full flex-col justify-between text-left">
              <p className="ml-2 text-sm font-medium text-gray-100 duration-150 group-hover:text-white">
                {activeOrganization?.name}
              </p>
              <p className="ml-2 text-xs font-medium text-gray-400 duration-150 group-hover:text-gray-300">
                {user?.name}
              </p>
            </div>
          </div>
        ) : (
          <div className="group ml-2 cursor-pointer">
            <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-sm font-semibold text-gray-100 duration-150 group-hover:bg-indigo-600 group-hover:text-white">
              <p>{organizationInitials(activeOrganization?.name)}</p>
            </div>
          </div>
        )}
      </MenuButton>
      <OptionsDropdown
        orgId={activeOrganization?.id}
        orgName={activeOrganization?.name}
        role={activeOrganization?.role}
        user={user}
        toggleSideBar={toggleSideBar}
        switchOrgView={switchOrgView}
        setSwitchOrgView={setSwitchOrgView}
      />
    </Menu>
  );
};

const OptionsDropdown = React.memo(function OptionsDropdown({
  orgId,
  orgName,
  role,
  user,
  toggleSideBar,
  switchOrgView,
  setSwitchOrgView
}: {
  orgId?: string | undefined;
  orgName?: string;
  role?: string;
  user: User | undefined;
  toggleSideBar?: () => void;
  switchOrgView: boolean;
  setSwitchOrgView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { data } = useGetUserProfile();
  const { data: invitations } = useGetUserInvitations();
  const { switchOrg } = useSwitchOrganization();
  const isMedium = useBreakpoint('md');

  const organizations = data?.organizations || [];
  const invitationsCount = invitations?.length || 0;

  return (
    <>
      {!switchOrgView && (
        <MenuItems
          anchor="bottom start"
          className={classNames(
            'ml-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none',
            isMedium ? 'mt-2 w-72' : '!top-2 right-2 z-50 mb-2 !max-h-none bg-white'
          )}
        >
          <div className="py-1">
            <MenuItem>
              <span className="absolute right-4 top-4 inline-flex cursor-pointer justify-center text-gray-400">
                <XMarkIcon className="h-5 w-5" />
              </span>
            </MenuItem>
            <MenuItem>
              <div className="flex items-center px-4 py-1">
                <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-sm font-semibold text-white">
                  <p>{organizationInitials(orgName)}</p>
                </div>
                <div className="flex h-full flex-col justify-between text-left">
                  <p className="ml-2 text-sm font-medium text-gray-700">{orgName}</p>
                  <p className="ml-2 text-xs font-medium text-gray-400">
                    {t('components.user.common.role', { context: role })}
                  </p>
                </div>
              </div>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => toggleSideBar?.()}
                to={routes.ORGANIZATION_SETTINGS}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {t('components.common.organizationSettings')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => toggleSideBar?.()}
                to={routes.ORGANIZATION_SETTINGS_TEAM}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {t('components.user.common.members')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => toggleSideBar?.()}
                to={routes.ORGANIZATION_SETTINGS_SUBSCRIPTION}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {t('components.user.common.subscription')}
              </Link>
            </MenuItem>
            <button
              onClick={() => setSwitchOrgView(prev => !prev)}
              type="button"
              className="flex w-full items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <>{t('components.user.UserProfileAvatar.OptionsDropdown.switchOrg')}</>
              <ChevronRightIcon className="h-4 w-4 text-gray-400" />
            </button>
          </div>
          <div className="py-1">
            <MenuItem>
              <div className="flex items-center px-4 py-2">
                {user?.picture && (
                  <div>
                    <img className="inline-block h-10 w-10 rounded-full" src={user?.picture} alt={user?.name} />
                  </div>
                )}
                {!user?.picture && (
                  <div className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-400 text-sm font-semibold text-white">
                    <p>{usernameInitials(user?.name || user?.email)}</p>
                  </div>
                )}
                <div className="flex h-full flex-col justify-between text-left">
                  <p className="ml-2 max-w-[200px] truncate text-sm font-medium text-gray-700">
                    {user?.name || user?.email}
                  </p>
                  <p className="ml-2 max-w-[200px] truncate text-xs font-medium text-gray-400">{user?.email}</p>
                </div>
              </div>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => toggleSideBar?.()}
                to={routes.USER_SETTINGS}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {t('components.common.accountSettings')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => toggleSideBar?.()}
                to={routes.USER_INVITES}
                className="group flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {t('components.user.UserProfileAvatar.OptionsDropdown.incomingInvites')}
                <span
                  className={classNames(
                    invitationsCount === 0 && 'opacity-50',
                    'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'
                  )}
                >
                  {invitationsCount}
                </span>
              </Link>
            </MenuItem>
          </div>
          <div className="py-1">
            <form action="#" method="POST">
              <MenuItem>
                <button
                  type="button"
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                >
                  {t('general.action.logout')}
                </button>
              </MenuItem>
            </form>
          </div>
        </MenuItems>
      )}
      {switchOrgView && (
        <MenuItems
          anchor="bottom start"
          className={classNames(
            'ml-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none',
            isMedium ? 'mt-2 w-72' : '!top-2 right-2 z-50 mb-2 !max-h-none bg-white'
          )}
        >
          <div className="py-1">
            <button
              onClick={() => setSwitchOrgView(prev => !prev)}
              type="button"
              className="flex w-full items-center px-4 py-2 text-center text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <ChevronLeftIcon className="mr-2 h-4 w-4 text-gray-400" />
              <span className="">{t('general.action.back')}</span>
            </button>
          </div>
          <div className="h-64 overflow-y-auto py-1">
            {organizations.map(org => (
              <MenuItem key={org.id}>
                <div
                  className={classNames(
                    'group flex cursor-pointer items-center px-4 py-1',
                    orgId === org.id && 'bg-gray-100'
                  )}
                  onClick={() => {
                    setSwitchOrgView(false);
                    switchOrg(org.id);
                    !isMedium && toggleSideBar?.();
                  }}
                >
                  <div className="inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-md bg-indigo-500 text-sm font-semibold text-gray-100 duration-150 group-hover:bg-indigo-600 group-hover:text-white">
                    <p>{organizationInitials(org.name)}</p>
                  </div>
                  <div className="flex h-full flex-col justify-between text-left">
                    <p className="ml-2 text-sm font-medium text-gray-600 duration-150 group-hover:text-gray-700">
                      {org.name}
                    </p>
                    <p className="ml-2 text-xs font-medium text-gray-400 duration-150 group-hover:text-gray-500">
                      {t('components.user.common.role', { context: org.role })}
                    </p>
                  </div>
                </div>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      )}
    </>
  );
});
