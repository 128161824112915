import { ModelBaseWithDates, Template } from '..';

export enum AfterEffectsVersion {
  AENA = 'AENA',
  AE2022 = 'AE2022',
  AE2023_AMD64 = 'AE2023_AMD64',
  AE2024 = 'AE2024',
  AE2025 = 'AE2025'
}

export enum StrictFontChecks {
  DEFAULT = 'DEFAULT',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export type Project = ModelBaseWithDates & {
  createdBy?: string;
  analysis: ProjectAnalysis;
  uploaded: boolean;
  size: number;
  templates: Template[];
  defaultTemplateId?: string;
  name: string;
  description?: string;
  aeVersion?: AfterEffectsVersion;
  attributes?: Record<string, unknown> & {
    tags?: string[];
  };
  revisionHistory?: ProjectRevision[];
  sharing?: ProjectSharing;
  sharingLinks?: Record<string, string>;
  analyzed: boolean;
  strictFontChecks: StrictFontChecks | null;
};

type ProjectAnalysis = {
  done: boolean;
  pending: boolean;
  failed: boolean;
  error?: Record<string, string | object>;
  upgradeError?: Record<string, string | object>;
};

type ProjectRevision = {
  id: string;
  createdDate: string;
};

type ProjectSharing = {
  enabled: boolean;
  enabledDate: string;
  autoLatest: boolean;
};

export type ProjectShareDto = {
  enabled: boolean;
  autoLatest?: boolean;
};
