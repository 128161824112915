import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  DesignBooleanInput,
  DesignColorInput,
  DesignNumberInput,
  DesignStringInput,
  FieldLabel,
  MediaLayerInput
} from '@src/components';
import { DesignParameter, DesignParameterType } from '@src/models';
import { ensureColorHex } from '@src/utils';

export type DesignFormFieldProps = {
  hideLabel?: boolean;
  value?: string | number | boolean;
  parameter: DesignParameter;
  onChange: (name: string, value: string) => void;
  onValidation?: (key: string) => (url: string, valid: boolean) => void;
  errors?: string[];
  required?: boolean;
};

export const DesignFormField = ({
  parameter,
  onChange,
  value,
  hideLabel = false,
  onValidation,
  errors,
  required
}: DesignFormFieldProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'col-span-6 text-gray-900 sm:col-span-3',
        parameter.type === DesignParameterType.BOOLEAN && 'flex',
        !hideLabel && 'mt-3'
      )}
    >
      {parameter.type === DesignParameterType.BOOLEAN && (
        <DesignBooleanInput
          id={`${parameter.name}-field`}
          name={`${parameter.name}-name`}
          aria-describedby={`${parameter.name}-description`}
          onFieldUpdate={value => onChange(parameter.key, value.toString())}
          checked={value === 'true' || value === true || parameter.defaultValue}
          required={required}
          errors={errors}
        />
      )}

      {!hideLabel && (
        <div className="mb-1">
          <FieldLabel
            key={parameter.key}
            name={parameter.name}
            parameterKey={parameter.key}
            optional={parameter.optional}
            description={parameter.description}
          />
        </div>
      )}
      <>
        {parameter.type === DesignParameterType.STRING && (
          <DesignStringInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={value => onChange(parameter.key, value)}
            value={value?.toString()}
            required={required}
            errors={errors}
          />
        )}
        {parameter.type === DesignParameterType.COLOR && (
          <DesignColorInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={value => onChange(parameter.key, value)}
            value={ensureColorHex(value?.toString())}
            required={required}
            errors={errors}
          />
        )}
        {parameter.type === DesignParameterType.MEDIA && (
          <MediaLayerInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={(value: string) => onChange(parameter.key, value)}
            value={value?.toString()}
            onValidation={onValidation?.(parameter.key)}
            required={required}
            errors={errors}
          />
        )}
        {parameter.type === DesignParameterType.NUMBER && (
          <DesignNumberInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            step="any"
            onFieldUpdate={value => {
              onChange(parameter.key, value.toString());
            }}
            value={Number(value) || parameter.defaultValue || 0}
            required={required}
            errors={errors}
          />
        )}
        <Alert
          className="mt-1"
          show={parameter.type === DesignParameterType.COMPLEX}
          type="info"
          alertContent={t('components.designs.common.complexParameterAlert')}
        />
      </>
    </div>
  );
};
