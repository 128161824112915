import React from 'react';

import { UpdatableInput } from './UpdatableInput';

type Option = {
  value: string;
  label: string;
  isSelected?: boolean;
};

type UpdatableDropdownProps = {
  value: string;
  loading: boolean;
  onUpdate: (value: string) => Promise<void>;
  options: Option[];
};

export const UpdatableDropdown: React.FC<UpdatableDropdownProps> = ({ value, loading, onUpdate, options }) => (
  <UpdatableInput
    value={value}
    loading={loading}
    onUpdate={onUpdate}
    renderInput={({ disabled, autoFocus, setValue }) => (
      <select
        disabled={disabled}
        autoFocus={autoFocus}
        defaultValue={options.find(option => option.isSelected)?.value}
        onChange={e => setValue(e.target.value)}
        className="relative -mb-[1px] block w-full border-0 border-b border-gray-500 bg-inherit p-0 text-sm text-gray-900 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )}
  />
);
