import { Design, DesignVariant, Integrations, Project, Template, Webhook } from '@src/models';

export * from './notification';

export type PropsWithModel<T> = {
  model: T;
  loading?: boolean;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type BatchRenderPassthrough = {
  passthrough: { [param: string]: { value: string; isColumnSelect: boolean } };
  sendAsJson: boolean;
};

export enum BatchRenderType {
  csv = 'csv'
}

export type ProjectItem = {
  isDesign: false;
  id: string;
  item: Project;
};

export type DesignItem = {
  isDesign: true;
  id: string;
  item: Design;
};

export type TemplateItem = {
  isDesign: false;
  id: string;
  item: Template;
};

export type DesignVariantItem = {
  isDesign: true;
  id: string;
  item: DesignVariant;
};

export type AnyProjectItem = ProjectItem | DesignItem;
export type AnyRenderItem = TemplateItem | DesignVariantItem;

export type WebhookData = Omit<Webhook, 'passthrough'>;
export type IntegrationsData = Omit<Integrations, 'passthrough'>;

export type SelectedDynamicScripts = {
  [parameterName: string]: {
    [key: string]: { value: string | number | boolean | readonly string[] | undefined; isColumnSelect: boolean };
  };
};
