import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { StyledA } from '@src/components/common';
import { LayerManagementScript } from '@src/models';

type LayerManagementFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: LayerManagementScript | undefined) => void;
  editScript?: LayerManagementScript;
};

export const LayerManagementForm = ({ setFormValid, setFormScript, editScript }: LayerManagementFormProps) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<LayerManagementScript>({
    scriptType: ScriptType.LAYER_MANAGEMENT,
    parameterName: editScript?.parameterName || ''
  });

  const valid = inputs.parameterName !== '';

  useEffect(() => {
    setFormValid(valid);
    if (valid) {
      setFormScript(inputs);
    } else {
      setFormScript(undefined);
    }
  }, [valid, inputs, setFormValid, setFormScript]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset>
        <div>
          <label htmlFor="parameterName" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.common.parameterName')}
            <p className="text-sm font-normal text-gray-500">
              <Trans i18nKey={'components.project.template.layer.scripts.LayerManagementForm.parameterNameDesc'}>
                Name of the parameter that will be used to pass data that defines layer properties. The parameter
                structure and supported options are described
                <StyledA
                  href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#layer-management`}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </StyledA>
                .
              </Trans>
            </p>
          </label>
          <input
            autoFocus
            id="parameterName"
            name="parameterName"
            type="text"
            pattern="^[^\.]+$"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={e => setInputs({ ...inputs, parameterName: e.target.value.trim() })}
            defaultValue={inputs.parameterName}
          />
        </div>
      </fieldset>
    </div>
  );
};
