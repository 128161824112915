import { useCallback, useMemo } from 'react';

import { DynamicScriptType } from '@plainly/types';
import { SelectedDynamicScripts } from '@src/types';

import { DynamicScriptLayerManagement } from './DynamicScriptLayerManagement';

export const DynamicScriptBatchRenderWrapper = ({
  selectedDynamicScripts,
  parameterName,
  scriptType,
  onChange,
  columns
}: {
  selectedDynamicScripts: SelectedDynamicScripts;
  parameterName: string;
  scriptType: DynamicScriptType;
  onChange: (col: SelectedDynamicScripts) => void;
  columns: string[] | undefined;
}) => {
  const parameter = useMemo(() => selectedDynamicScripts?.[parameterName], [parameterName, selectedDynamicScripts]);

  const handleBatchChange = useCallback(
    (value: string | number | boolean | readonly string[] | undefined, property: string, isColumnSelect: boolean) => {
      const newSelectedDynamicScripts = { ...selectedDynamicScripts };

      newSelectedDynamicScripts[parameterName] = {
        ...newSelectedDynamicScripts[parameterName],
        [property]: { value, isColumnSelect }
      };

      onChange(newSelectedDynamicScripts);
    },
    [onChange, parameterName, selectedDynamicScripts]
  );

  const isColumnSelect = useCallback(
    (property: string) => {
      if (selectedDynamicScripts?.[parameterName]?.[property]?.isColumnSelect !== undefined) {
        return selectedDynamicScripts[parameterName][property].isColumnSelect;
      }

      return true;
    },
    [parameterName, selectedDynamicScripts]
  );

  return (
    <>
      {scriptType === DynamicScriptType.LAYER_MANAGEMENT && (
        <DynamicScriptLayerManagement
          batchRender={true}
          parameter={parameter}
          onChange={handleBatchChange}
          isColumnSelect={isColumnSelect}
          columns={columns}
        />
      )}
    </>
  );
};
