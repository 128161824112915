import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DesignParameter } from '@src/models';

import { StyledA } from '../common';
import { DesignFormField } from '../designs';

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type ColumnSelectFieldProps = SelectProps &
  InputProps & {
    parameter?: DesignParameter;
    onFieldUpdate: (value: string, isColumnSelect: boolean) => void;
    options: string[];
    columnSelect: boolean;
    onValidation?: (key: string) => (url: string, valid: boolean) => void;
    excludeSwitchLink?: boolean;
    label?: string;
  };

export const ColumnSelectField = ({
  parameter,
  value,
  onFieldUpdate,
  options,
  columnSelect,
  onValidation,
  excludeSwitchLink,
  label,
  type = 'text',
  ...rest
}: ColumnSelectFieldProps) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);

  const fieldValue = value ?? '';

  return (
    <>
      {label && (
        <label htmlFor={label} className="mb-1 block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      {columnSelect ? (
        <select
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base text-gray-900 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={e => onFieldUpdate(e.target.value, true)}
          value={fieldValue}
          required={rest.required}
        >
          <option disabled={true} value={''}>
            {t('components.render.RenderBatchTable.ColumnSelectField.selectColumn')}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <>
          {parameter ? (
            <DesignFormField
              hideLabel={true}
              parameter={parameter}
              value={value as string}
              onChange={(_, value) => onFieldUpdate(value, false)}
              onValidation={onValidation}
              required={rest.required}
            />
          ) : (
            <input
              autoFocus={focus}
              className="block w-full rounded-md border-gray-300 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              autoComplete="off"
              type={type}
              onChange={e => onFieldUpdate(e.target.value, false)}
              value={fieldValue}
              {...rest}
            />
          )}
        </>
      )}
      {!excludeSwitchLink && (
        <StyledA
          onClick={() => {
            const update = !columnSelect;
            onFieldUpdate('', update);
            setFocus(true);
          }}
          className="float-right mt-1 block cursor-pointer text-sm font-medium"
        >
          {t('components.render.RenderBatchTable.ColumnSelectField.columnFieldSwitch', { context: `${columnSelect}` })}
        </StyledA>
      )}
    </>
  );
};
