import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Clipboard } from 'ts-clipboard';

import {
  CalendarIcon,
  CircleStackIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  VideoCameraIcon
} from '@heroicons/react/20/solid';
import { ClipboardDocumentCheckIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useNotifications } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ApiKeyDto, ResourceType } from '@src/models';
import { formatResourceUsage, toMb } from '@src/utils';

import { StyledA } from '../common';

export const ApiKeyItem = ({ apiKey }: { apiKey: ApiKeyDto }) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();
  const [shown, setShown] = useState(false);

  const toggleApiKeyShown = () => setShown(prev => !prev);

  const copyToClipboard = () => {
    Clipboard.copy(apiKey.key);
    notifyInfo(t('components.user.UserOrganizationCard.copyToClipboardNotification'));
  };

  return (
    <>
      {/* mobile screen */}
      <li className="flex flex-col gap-1 p-3 lg:hidden">
        <div className="flex items-center justify-between">
          <p>{apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}</p>
          <Actions t={t} shown={shown} toggleApiKeyShown={toggleApiKeyShown} copyToClipboard={copyToClipboard} />
        </div>
        <Key apiKey={apiKey} shown={shown} />
        <Expire apiKey={apiKey} t={t} />
        <ApiKeyLimits limits={apiKey.limits} t={t} />
      </li>

      {/* large screen */}
      <li className="hidden w-full flex-col gap-1 p-3 lg:flex xl:hidden">
        <div className="flex w-full items-center">
          <p className="truncate">
            {apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}
          </p>
          <Actions t={t} shown={shown} toggleApiKeyShown={toggleApiKeyShown} copyToClipboard={copyToClipboard} />
        </div>
        <div className="flex flex-col gap-1">
          <Key apiKey={apiKey} shown={shown} />
          <ApiKeyLimits limits={apiKey.limits} t={t} />
          <Expire apiKey={apiKey} t={t} />
        </div>
      </li>

      {/* extra large screen */}
      <li className="hidden w-full flex-col gap-1 p-3 xl:flex">
        <div className="flex w-full items-center">
          <p className="w-1/6 truncate">
            {apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}
          </p>
          <Key apiKey={apiKey} shown={shown} />
          <ApiKeyLimits limits={apiKey.limits} t={t} />
          <Actions t={t} shown={shown} toggleApiKeyShown={toggleApiKeyShown} copyToClipboard={copyToClipboard} />
        </div>
        <Expire apiKey={apiKey} t={t} />
      </li>
    </>
  );
};

const Actions = ({
  t,
  shown,
  toggleApiKeyShown,
  copyToClipboard
}: {
  t: TFunction;
  shown: boolean;
  toggleApiKeyShown: () => void;
  copyToClipboard: () => void;
}) => (
  <>
    <div className="flex items-center gap-2 md:hidden">
      {shown && <EyeIcon onClick={toggleApiKeyShown} className="h-5 w-5 shrink-0 text-indigo-600" />}
      {!shown && <EyeSlashIcon onClick={toggleApiKeyShown} className="h-5 w-5 shrink-0 text-indigo-600" />}
      <ClipboardDocumentCheckIcon onClick={copyToClipboard} className="h-5 w-5 shrink-0 text-indigo-600" />
    </div>
    <div className="ml-auto hidden items-center gap-2 md:flex md:gap-4">
      <div className="w-8 flex-none">
        <StyledA onClick={toggleApiKeyShown} href="#">
          {t('components.user.UserOrganizationCard.apiKeyShown', { context: `${shown}` })}
        </StyledA>
      </div>
      <div className="shrink-0">
        <StyledA onClick={copyToClipboard} className="truncate" href="#">
          {t('general.action.copyToClipboard')}
        </StyledA>
      </div>
    </div>
  </>
);

const Key = ({ apiKey, shown }: { apiKey: ApiKeyDto; shown: boolean }) => (
  <div className="flex items-center gap-1 xl:w-80">
    <ShieldCheckIcon className="h-5 w-5 shrink-0 text-gray-400" />
    <span className="truncate" id="apiKey">
      {shown ? apiKey.key : '**************************'}
    </span>
  </div>
);

const Expire = ({ apiKey, t }: { apiKey: ApiKeyDto; t: TFunction }) => (
  <div className="flex items-center gap-1 whitespace-nowrap">
    <CalendarIcon
      className="h-5 w-5 shrink-0 text-gray-400"
      title={
        apiKey.expiryDate &&
        t('components.user.UserOrganizationCard.apiKeyExpires', {
          date: localizationHelper.forDate().formatDateTimeStringLocally(apiKey.expiryDate)
        })
      }
    />
    <span>
      {apiKey.expired
        ? t('components.common.expired')
        : (apiKey.expiryDate &&
            t('components.user.UserOrganizationCard.apiKeyExpires', {
              date: localizationHelper.forDate().formatDateStringLocally(apiKey.expiryDate)
            })) ||
          t('components.user.UserOrganizationCard.apiKeyNeverExpires')}
    </span>
  </div>
);

const ApiKeyLimits = ({ limits, t }: { limits?: Partial<Record<ResourceType, number>>; t: TFunction }) => (
  <>
    {limits && (
      <div className="flex items-center gap-3 xl:mr-2">
        {limits.STORAGE && (
          <div className="flex items-center gap-1">
            <CircleStackIcon className="h-5 w-5 shrink-0 text-gray-400" />
            <span className="whitespace-nowrap">
              {t('components.user.UserOrganizationCard.resourceUsage', {
                context: 'STORAGE',
                count: Math.round(limits.STORAGE / 1024 / 1024),
                megabytes: formatResourceUsage(toMb(limits.STORAGE), 0)
              })}
            </span>
          </div>
        )}
        {limits.RENDERING_VIDEO_DURATION && (
          <div className="flex items-center gap-1">
            <VideoCameraIcon className="h-5 w-5 shrink-0 text-gray-400" />
            <span className="whitespace-nowrap">
              {t('components.user.UserOrganizationCard.resourceUsage', {
                context: 'RENDERING_VIDEO_DURATION',
                count: Math.round(limits.RENDERING_VIDEO_DURATION / 60),
                decimal: formatResourceUsage(limits.RENDERING_VIDEO_DURATION / 60, 0),
                minutes: formatResourceUsage(limits.RENDERING_VIDEO_DURATION / 60, 0)
              })}
            </span>
          </div>
        )}
        {limits.ARTICLE_VIDEOS && (
          <div className="flex items-center gap-1">
            <NewspaperIcon className="h-5 w-5 shrink-0 text-gray-400" />
            <span className="whitespace-nowrap">
              {t('components.user.UserOrganizationCard.resourceUsage', {
                context: 'ARTICLE_VIDEOS',
                count: limits.ARTICLE_VIDEOS
              })}
            </span>
          </div>
        )}
      </div>
    )}
  </>
);
