import React from 'react';

import { UpdatableInput } from './UpdatableInput';

type UpdatableStringProps = {
  value: string;
  loading: boolean;
  onUpdate: (value: string) => Promise<void>;
};

export const UpdatableString: React.FC<UpdatableStringProps> = ({ value, loading, onUpdate }) => (
  <UpdatableInput
    value={value}
    loading={loading}
    onUpdate={onUpdate}
    renderInput={({ disabled, autoFocus, value, setValue }) => (
      <input
        disabled={disabled}
        autoFocus={autoFocus}
        value={value}
        onChange={e => setValue(e.target.value)}
        required
        className="relative -mb-[1px] block w-full border-0 border-b border-gray-500 bg-inherit p-0 text-sm text-gray-900 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
        type="text"
      />
    )}
  />
);
